<template>
  <div class="content-wrapper">
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="mb-5">Faqs</h1>
      <b-button
        :disabled="loadingGroups"
        v-b-toggle.sidebar-group
        size="sm"
        class="mb-2"
      >
        <b-icon icon="plus" aria-hidden="true"></b-icon> Add group
      </b-button>
    </div>

    <!-- <b-card class="mb-4">
      <b-row class="my-1">
        <b-col lg="3">
          <b-input-group size="sm" class="mb-2">
            <b-input-group-prepend is-text>
              <b-icon icon="search"></b-icon>
            </b-input-group-prepend>
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Search"
            ></b-form-input>
          </b-input-group>
        </b-col>
        <b-col>
          <view-lang-select v-model="view_lang" :options="languages" />
        </b-col>
        <b-col class="text-lg-right">
          <b-button @click="addGroup" size="sm" class="mb-2">
            <b-icon icon="plus" aria-hidden="true"></b-icon> Add group
          </b-button>
        </b-col>
      </b-row>
    </b-card> -->

    <b-overlay :show="loadingGroups" rounded="sm" class="overlay-placeholder">
      <draggable
        :list="items"
        handle=".faq-handle"
        @change="postNewGroupPosition"
      >
        <b-card v-for="(group, index) in items" :key="index" class="my-3">
          <div class="d-flex justify-content-between">
            <div>
              <h3>{{ group.name[view_lang] }}</h3>
            </div>
            <div class="text-lg-right">
              <div>
                <b-button
                  size="sm"
                  class="mb-2"
                  @click="
                    currentGroup = group;
                    tmpGroup = JSON.parse(JSON.stringify(group));
                  "
                  v-b-toggle.sidebar-faqs
                >
                  <b-icon icon="plus" aria-hidden="true"></b-icon> Add question
                </b-button>
              </div>
              <div>
                <b-icon
                  v-if="isSortable"
                  icon="arrows-move"
                  class="pointer faq-handle"
                ></b-icon>
                <b-icon-gear-fill
                  @click="
                    currentGroup = group;
                    tmpGroup = JSON.parse(JSON.stringify(group));
                  "
                  v-b-toggle.sidebar-group
                  class="pointer ml-2"
                />
                <b-icon-trash-fill
                  @click="
                    currentGroup = group;
                    showDeleteModalGroup = true;
                  "
                  class="pointer ml-2"
                />
              </div>
            </div>
          </div>
          <draggable
            :list="group.questions"
            handle=".faq-handle"
            @change="postNewFaqPosition"
          >
            <b-card v-for="faq in group.questions" :key="faq.id" class="my-3">
              <div class="d-flex">
                <h4>{{ faq.question[view_lang] }}</h4>
                <div class="ml-auto pl-3 flex-shrink-0 text-primary">
                  <b-icon
                    v-if="isSortable"
                    icon="arrows-move"
                    class="pointer faq-handle"
                  ></b-icon>
                  <b-icon-gear-fill
                    @click="
                      currentFaq = faq;
                      tmpFaq = JSON.parse(JSON.stringify(faq));
                      currentGroup = group;
                      tmpGroup = JSON.parse(JSON.stringify(group));
                    "
                    v-b-toggle.sidebar-faqs
                    class="pointer ml-2"
                  />
                  <b-icon-trash-fill
                    @click="
                      currentFaq = faq;
                      showDeleteModal = true;
                    "
                    class="pointer ml-2"
                  />
                </div>
              </div>
              <p>{{ faq.answer[view_lang] }}</p>
            </b-card>
          </draggable> </b-card
        >s
      </draggable>
    </b-overlay>
    <b-sidebar
      class="right-sidebar"
      id="sidebar-faqs"
      right
      shadow
      lazy
      @hidden="resetFaq"
    >
      <div class="content-wrapper my-3 mx-1">
        <h4 v-if="currentFaq">Edit Faq</h4>
        <h4 v-else>New Faq</h4>

        <b-form-group label="Question">
          <b-form-input v-model="tmpFaq.question[question_lang]" />
          <LangOptions v-model="question_lang" :options="languages" />
        </b-form-group>
        <b-form-group label="Answer">
          <b-form-textarea v-model="tmpFaq.answer[answer_lang]" rows="3" />
          <LangOptions v-model="answer_lang" :options="languages" />
        </b-form-group>
        <div class="mt-5 mb-5">
          <b-button
            squared
            variant="primary"
            size="lg"
            class="big-button"
            @click="saveItem"
            >Confirm</b-button
          >
        </div>
      </div>
    </b-sidebar>
    <b-sidebar
      class="right-sidebar"
      id="sidebar-group"
      right
      shadow
      lazy
      @hidden="resetGroup"
    >
      <div class="content-wrapper my-3 mx-1">
        <h4 v-if="currentGroup">Edit Group</h4>
        <h4 v-else>New Group</h4>

        <b-form-group label="Name">
          <b-form-input v-model="tmpGroup.name[view_lang]" />
          <LangOptions v-model="view_lang" :options="languages" />
        </b-form-group>

        <div class="mt-5 mb-5">
          <b-button
            squared
            variant="primary"
            size="lg"
            class="big-button"
            @click="saveGroup"
            >Confirm</b-button
          >
        </div>
      </div>
    </b-sidebar>
    <b-modal
      v-model="showDeleteModal"
      title="Attention!"
      ok-title="Confirm"
      cancel-title="Cancel"
      @hidden="resetFaq"
      @ok="deleteItem(currentFaq.id)"
      >Do you really want to delete the selected element?</b-modal
    >
    <b-modal
      v-model="showDeleteModalGroup"
      title="Attention!"
      ok-title="Confirm"
      cancel-title="Cancel"
      @hidden="resetFaq"
      @ok="deleteGroup(currentGroup.id)"
      >Do you really want to delete the selected element?</b-modal
    >
  </div>
</template>

<script>
import draggable from "vuedraggable";
import AxiosService from "./../axiosServices/AxiosService";
import { mapGetters } from "vuex";
export default {
  components: { draggable },
  data() {
    return {
      items: [],
      currentPage: 1,
      perPage: 10,
      filter: "",
      currentFaq: null,
      currentGroup: null,
      tmpFaq: {},
      tmpGroup: { name: {} },
      showDeleteModal: false,
      showDeleteModalGroup: false,

      question_lang: null,
      answer_lang: null,
      view_lang: null,

      loadingGroups: false,

      currentCompany: null,
    };
  },
  service: null,
  serviceFaq: null,
  created() {
    this.currentCompany = this.currentCompanyFromStore;
    this.service = new AxiosService(
      "FaqGroups/" + this.currentCompanyFromStore
    );
    this.serviceFaq = new AxiosService("Faq/" + this.currentCompanyFromStore);
    this.view_lang = this.default_lang.value || this.default_lang;
    this.resetFaq();
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.loadingGroups = true;
      this.items.splice(0);

      const service = new AxiosService(
        "FaqGroups/" + this.currentCompanyFromStore
      );

      await service
        .read()
        .then((data) => {
          // (this.items = data)
          data.forEach((group) => {
            this.items.push({ ...group, questions: [] });
            let groupService = new AxiosService("FaqByGroup/" + group.id);

            groupService.read().then((res) => {
              let tmpIndex = this.items.findIndex((x) => x.id == group.id);
              this.items[tmpIndex].questions = [...res];
            });
          });
        })
        .finally(() => {
          this.loadingGroups = false;
        });
    },
    saveGroup(
      options = {
        toggleSidebar: true,
        showToast: true,
      }
    ) {
      this.loadingGroups = true;

      let item = {
        ...this.tmpGroup,
        id_company: this.currentCompany,
      };

      console.log("saving group", item, this.currentGroup);

      const service = new AxiosService("FaqGroup");

      if (item.id) {
        service
          .update(item)
          .then(() => {
            if (options.toggleSidebar)
              this.$root.$emit("bv::toggle::collapse", "sidebar-group");
            if (options.showToast) this.$successToast();
          })
          .catch((err) => {
            console.log("error", err);
            this.$errorToast();
          })
          .finally(() => {
            this.loadingGroups = false;
          });
      } else {
        service
          .create(item)
          .then(() => {
            if (options.toggleSidebar)
              this.$root.$emit("bv::toggle::collapse", "sidebar-group");
            if (options.showToast) this.$successToast();
            this.getData();
          })
          .catch(() => {
            this.$errorToast();
          })
          .finally(() => {
            this.loadingGroups = false;
          });
      }
    },
    resetFaq() {
      this.currentFaq = null;
      this.tmpFaq = {
        question: {},
        answer: {},
      };
      this.question_lang = this.default_lang.value;
      this.answer_lang = this.default_lang.value;
      this.validation_messages = "";
    },
    resetGroup() {
      this.currentGroup = null;
      this.tmpGroup = {
        name: {},
      };
      this.view_lang = this.default_lang.value || this.default_lang;
    },
    saveItem() {
      let item = { ...this.tmpFaq };
      item.group = this.tmpGroup.id;
      item.id_company = this.currentCompany;
      const service = new AxiosService("Faq");

      const validationObject = this.$validate("faq", item);
      if (!validationObject.validation) {
        this.$errorToast(validationObject.messages.join(", "));
        return false;
      }

      if (item.id) {
        service
          .update(item)
          .then((response) => {
            if (response.id == item.id) {
              this.currentFaq.question = item.question;
              this.currentFaq.answer = item.answer;
              this.$root.$emit("bv::toggle::collapse", "sidebar-faqs");
              this.$successToast();
            } else {
              this.$errorToast();
              return false;
            }
          })
          .catch(() => {
            this.$errorToast();
            return false;
          })
          .finally(() => {
            this.getData();
          });
      } else {
        item.order = this.items.length;
        service
          .create(item)
          .then((response) => {
            if (response.id) {
              item.id = response.id;
              this.items.push(item);
              this.$root.$emit("bv::toggle::collapse", "sidebar-faqs");
              this.$successToast();
            } else {
              this.$errorToast();
              return false;
            }
          })
          .catch(() => {
            this.$errorToast();
            return false;
          })
          .finally(() => {
            this.getData();
          });
      }
    },
    deleteItem(id) {
      const service = new AxiosService("Faq");
      service
        .delete(id)
        .then((response) => {
          if (response) {
            this.$successToast();
          } else {
            this.$errorToast();
          }
        })
        .catch(() => {
          this.$errorToast();
        })
        .finally(() => {
          this.getData();
        });
    },
    deleteGroup(id) {
      const service = new AxiosService("FaqGroup");
      service
        .delete(id)
        .then((response) => {
          if (response) {
            this.$successToast();
          }
        })
        .catch(() => {
          this.$errorToast();
        })
        .finally(() => {
          this.getData();
        });
    },
    postNewGroupPosition() {
      console.log("postNewGroupPosition", {
        items: this.items,
      });

      for (let i = 0; i < this.items.length; i++) {
        this.items[i].order = i;

        this.tmpGroup = { ...this.items[i] };

        this.saveGroup({
          toggleSidebar: false,
          showToast: false,
        });
      }
    },
    postNewFaqPosition() {
      let newOrder = [];
      for (let i = 0; i < this.items.length; i++) {
        newOrder.push({ ID: this.items[i].id, Ordine: i });
      }
      this.serviceFaq
        .updateOrder(newOrder)
        .then(() => {
          this.$successToast();
        })
        .catch(() => {
          this.$errorToast();
          return false;
        });
    },
  },
  computed: {
    ...mapGetters(["languages"]),
    ...mapGetters(["default_lang"]),
    totalRows() {
      return this.items.length;
    },
    filteredItems() {
      if (this.filter) {
        return this.items.filter((item) => {
          return (
            item.question[this.question_lang]
              .toLowerCase()
              .includes(this.filter.toLowerCase()) ||
            item.answer[this.answer_lang]
              .toLowerCase()
              .includes(this.filter.toLowerCase())
          );
        });
      } else {
        return this.items;
      }
    },
    isSortable() {
      return this.filter ? false : true;
    },
    currentCompanyFromStore() {
      console.log("from store ", this.$store.getters.currentCompany);
      return this.$store.getters.currentCompany;
    },
  },
  watch: {
    currentCompanyFromStore() {
      console.log("watch store", this.currentCompanyFromStore);
      this.currentCompany = this.currentCompanyFromStore;
      console.log("trig GET DATA WITH ", this.currentCompanyFromStore);
      this.getData();
    },
    currentCompany() {
      this.$store.dispatch("SET_CURRENT_COMPANY", this.currentCompany);
    },
  },
};
</script>
